import styles from '@styles/scss/common/_footer.module.scss';
import { FooterText } from 'lib/types/item/footer';
import { FooterLinks } from 'lib/types/item/footerLinks';

import UserFooterLinks from './userFooterLinks';

export default function Footer({
    footerText,
    footerLinks,
    currentLang,
    gender
}: {
    footerText: FooterText;
    footerLinks: FooterLinks;
    currentLang: string;
    gender: string;
}): JSX.Element {
    return (
        <footer>
            <div className={styles.footer}>
                <div className={styles.footerCol1}>
                    <h2>{footerText.left_text_h2}</h2>
                    <p>{footerText.left_text}</p>
                </div>
                <div className={styles.footerCol2}>
                    <h2>{footerText.right_text_h2}</h2>
                    <p>{footerText.right_text}</p>
                    <hr></hr>
                    <hr></hr>
                    <UserFooterLinks
                        footerLinks={footerLinks}
                        currentLang={currentLang}
                        gender={gender}
                    />
                </div>
            </div>
        </footer>
    );
}
